<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">
          enacted motions management
        </div>
      </v-row>
      <v-row class="">
        <div class="">
          Manage and edit enacted motions and mark them as bills when they are
          passed
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <div class="d-flex">
        <v-spacer></v-spacer>

        <v-text-field
          label="Search"
          outlined
          dense
          append-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </div>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="motionsEnacted"
        class="text-capitalize"
      >
        <!-- <template v-slot:[`item.committee`]="{ item }">
          {{ item.committee == null ? "None" : item.committee }}
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn
              v-if="(item.status == 'bill') | (item.status == 'act')"
              small
              rounded
              color="success"
              text
              outlined
              :ripple="false"
              >passed
            </v-btn>
            <v-btn
              v-else
              small
              rounded
              color="primary"
              text
              outlined
              @click="billCreate(item)"
              >mark as passed
            </v-btn>
          </div>
        </template>
      </v-data-table>

      <v-dialog width="auto" v-model="makeBillDialog">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">mark as passed</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-form v-model="makeBill" ref="makeBill">
              <v-text-field
                label="Ordinance No"
                v-model="resolutionNumber"
                :rules="[rules.required]"
                outlined
                dense
              ></v-text-field>
              <v-file-input
                label="Attach document"
                v-model="billDocument"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-form>

            <v-btn
              color="primary"
              :disabled="!makeBill"
              :loading="billLoader"
              block
              @click="motionUpdate()"
              >pass motion</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackBar"
      :color="snackBarData.success ? 'success' : 'error'"
    >
      <div class="text-capitalize text-center">
        {{ snackBarData.message }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { motionStore } from "@/stores/motions";
import { mapState, mapActions } from "pinia";
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "title/chapter",
          value: "title",
          class: "text-uppercase black--text",
        },
        {
          text: "subject",
          value: "subject",
          class: "text-uppercase black--text",
        },
        {
          text: "author",
          value: "author",
          class: "text-uppercase black--text",
        },
        // {
        //   text: "commitee",
        //   value: "committee",
        //   class: "text-uppercase black--text",
        // },
        {
          text: "enaction date",
          value: "enacted_date",
          class: "text-uppercase black--text",
        },
        {
          text: "ordinance no.",
          value: "ordinance_no",
          class: "text-uppercase black--text",
        },
        {
          text: "description",
          value: "description",
          width: "25%",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      billDocument: null,
      makeBillDialog: false,
      resolutionNumber: "",
      addLoader: false,
      addForm: false,
      addDialog: false,
      rules: {
        required: (v) => !!v || "Required",
      },
      makeBill: false,
      billForm: false,
      billLoader: false,
      snackBar: false,
      snackBarData: {
        success: false,
        message: "",
      },
      activeItemId: null,
    };
  },
  computed: {
    ...mapState(motionStore, ["motionsEnacted"]),
  },
  methods: {
    ...mapActions(motionStore, ["getMotionsEnacted", "createBill"]),
    billCreate(item) {
      this.activeItemId = item.id;
      this.makeBillDialog = true;
    },
    motionUpdate() {
      this.billLoader = true;

      let formData = new FormData();
      formData.append("motion_id", this.activeItemId);
      formData.append("status", "bill");
      formData.append("resolution_no", this.resolutionNumber);
      formData.append("file_type", "bill");
      formData.append("file", this.billDocument);
      this.createBill(formData)
        .then(() => {
          this.showSnackBar(true, "bill created succesfully");
          this.getMotionsEnacted();
          this.$refs.makeBill.reset();
        })
        .catch((err) => {
          this.showSnackBar(false, err.response.data.message);
        })
        .finally(() => {
          this.billLoader = false;
          this.makeBillDialog = false;
        });
    },
    showSnackBar(success, message) {
      this.snackBar = true;
      this.snackBarData.success = success;
      this.snackBarData.message = message;
    },
  },
  mounted() {
    this.getMotionsEnacted();
  },
};
</script>

<style></style>
